import React, { useState } from "react";
import * as firebase from "firebase";
import { Link } from "react-router-dom";

function SubmitPaperComponent() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [pprName, setPprName] = useState();
  const [fileUrl, setFileUrl] = useState("");
  const [abstract, setAbstract] = useState();
  const [submitBtnLabel, setSubmitBtnLabel] = useState("SUBMIT");
  const [fileLable, setFileLable] = useState("Choose File");
  const [authorFieldNumber, setauthorFieldNumber] = useState([0]);
  const [authorFields, setAuthorFields] = useState([
    {
      name: "test1",
      email: "email1",
    },
  ]);

  const addNewAuthorField = () => {
    setauthorFieldNumber(
      authorFieldNumber.concat(
        authorFieldNumber[authorFieldNumber.length - 1] + 1
      )
    );
    setAuthorFields([
      ...authorFields,
      {
        name: "test",
        email: "email",
      },
    ]);
  };

  const changeValue = (index) => (e) => {
    let propertyName = e.target.name;
    let newArr = [...authorFields];
    newArr[index][propertyName] = e.target.value;
    setAuthorFields(newArr);
  };

  //   Field Handlers
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handlepprName = (e) => {
    setPprName(e.target.value);
  };
  const handleAbstract = (e) => {
    setAbstract(e.target.value);
  };
  const handleFileUpload = (e) => {
    setFileLable("Uploading..");
    console.log(e.target.files[0]);
    const fileName = e.target.files[0].name;
    // Upload to cloud
    if (
      fileName.split(".")[1] === "pdf" ||
      fileName.split(".")[1] === "doc" ||
      fileName.split(".")[1] === "docx"
    ) {
      let storageRef = firebase
        .storage()
        .ref("uploads/" + e.target.files[0].name);
      let task = storageRef.put(e.target.files[0]);
      task.on(
        "state_changed",
        (snapshot) => {
          let percentageUploaded =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setFileLable(`${percentageUploaded}% Uploaded`);
        },
        function error(err) {
          console.log(err);
          setFileLable("Error uploading file.");
        },
        async function complete() {
          await storageRef.getDownloadURL().then((url) => setFileUrl(url));
          setFileLable(`File ${fileName} uploaded successfully`);
        }
      );
    } else {
      setFileLable("Invalid file type!");
    }
  };

  const submitPaper = (e) => {
    e.preventDefault();
    setSubmitBtnLabel("Loading..");
    let allAuthors = "";
    authorFields.forEach(
      (data, index) =>
        (allAuthors += `${index + 1}. ${data.name}, ${data.email}<br/>`)
    );
    // Send Req
    fetch(`${process.env.REACT_APP_SERVER_URL}/sendEmail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        msg: `New paper submission has arrived:<p/>Name: ${name},<br/>E-Mail: ${email},<br/>Paper Name: ${pprName},<br/>Authors:- <br/>${allAuthors}<p/>Abstract: ${abstract},<br/>Paper File Url: ${fileUrl}<p/>Thanks`,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.message === "OK") {
          setName("");
          setEmail("");
          setPprName("");
          setAuthorFields([
            {
              name: "",
              email: "",
            },
          ]);
          setName("");
          setSubmitBtnLabel("Paper Submitted");
          setTimeout(() => setSubmitBtnLabel("SUBMIT"), 4000);
        } else {
          console.log(data.message);
          setSubmitBtnLabel("Try Again Later");
          setTimeout(() => setSubmitBtnLabel("SUBMIT"), 4000);
        }
      })
      .catch((e) => {
        console.log(e);
        setSubmitBtnLabel("Try Again Later");
        setTimeout(() => setSubmitBtnLabel("SUBMIT"), 4000);
      });
  };

  return (
    <form onSubmit={submitPaper}>
      <fieldset>
        <h3 className="mb-4">Submit Paper</h3>
        <div className="form-group">
          <label>Email address</label>
          <input
            type="email"
            id="email"
            className="form-control"
            placeholder="Enter email"
            value={email}
            onChange={handleEmail}
            required
          />
          <small className="form-text text-muted">
            Please provide your primary email address.
          </small>
        </div>
        <div className="form-group">
          <label>Full Name</label>
          <input
            type="text"
            id="name"
            className="form-control"
            placeholder="Enter full name"
            value={name}
            onChange={handleName}
            required
          />
        </div>
        <div className="form-group">
          <label>Paper Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter paper name"
            value={pprName}
            onChange={handlepprName}
            required
          />
        </div>

        {authorFieldNumber.map((data, index) => {
          return (
            <div className="form-group authors" key={index}>
              <label>Author#{index + 1}</label>
              <div className="row">
                <div className="col-sm-12 col-md-5 col-lg-5">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Author name"
                    name="name"
                    value={data.name}
                    onChange={changeValue(index)}
                    required
                  />
                </div>
                <div className="col-sm-10 col-md-5 col-lg-5">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter author email"
                    name="email"
                    value={data.email}
                    onChange={changeValue(index)}
                    required
                  />
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <button
                    type="button"
                    onClick={addNewAuthorField}
                    className="btn btn-primary btn-md"
                  >
                    Add Author
                  </button>
                </div>
              </div>
            </div>
          );
        })}

        <div className="form-group">
          <label>Abstract</label>
          <textarea
            rows="5"
            id="abstract"
            className="form-control"
            placeholder="Abstract"
            value={abstract}
            onChange={handleAbstract}
            required
          ></textarea>
        </div>

        <div className="form-group">
          <label>Paper(*.pdf, *.doc, *.docx)</label>
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              onChange={handleFileUpload}
              required
            />
            <label className="custom-file-label" htmlFor="customFile">
              {fileLable}
            </label>
          </div>
        </div>
        <br />
        <div className="form-check mb-2">
          <label className="form-check-label">
            <input
              className="form-check-input"
              id="tncCheck"
              type="checkbox"
              required
            />
            I accept all the{" "}
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </label>
        </div>

        <button type="submit" className="btn btn-primary submitBtn">
          {submitBtnLabel}
        </button>
      </fieldset>
    </form>
  );
}

export default SubmitPaperComponent;
