import React, { useEffect, useState } from "react"
import * as firebase from "firebase"

export default function CurrentIssue() {
  const [isLoading, setIsLoading] = useState(true)
  const [currentJournalData, setcurrentJournalData] = useState({})

  useEffect(() => {
    const db = firebase.database()
    db.ref("/currentIssue").on("value", snap => {
      setcurrentJournalData(snap.val()[0])
      setIsLoading(false)
    })
  }, [])
  // console.log(currentJournalData)
  return (
    <div>
      <h5 className='card-header text-center'>Current Issue</h5>
      {isLoading && (
        <div className='loader-container'>
          <div className='lds-ellipsis'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div className='card-body' style={{ position: "relative" }}>
        <p className='card-text text-center font-weight-bold'>
          {isLoading ? '' : (<a href={currentJournalData.link}>
            <img
              src={currentJournalData.img}
              width='200px'
              alt='Current Issue'
              style={{ cursor: "pointer" }}
            />
            <p />
            Click here to download
          </a>)}
        </p>
      </div>
    </div>
  )
}
