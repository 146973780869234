import React from "react";
import NoPic from "../images/No_Picture.jpg";

export default function TechSupport() {
  return (
    <div>
      <h2 className="mt-5">Technical Support</h2>
      <br />
      <div className="card">
        <h5 className="card-header font-weight-bold">Harwinder Singh</h5>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3 mb-4 mb-md-0 mb-lg-0 text-center">
              <img
                className="img-fluid"
                width="150"
                src={NoPic}
                alt="Harwinder Singh"
              />
            </div>
            <div className="col-sm-12 col-md-9 col-lg-9 my-4">
              <p>
                <span className="font-weight-bold">Contact:</span>{" "}
                <a href="tel:+917696674429">+91-7696674429</a>{", "}<a href="tel:+917009818138">+91-7009818138</a>
                <br />
                <span className="font-weight-bold">Email:</span>{" "}
                <a href="mailto:hrwndr97@gmail.com">hrwndr97@gmail.com</a>
                <br />
                <span className="font-weight-bold">Website:</span>{" "}
                <a href="https://bit.ly/iamhrwndr" target="_blank">
                  http://bit.ly/iamhrwndr
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
