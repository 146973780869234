import React from "react";

const NotFoundComponent = ({ location }) => (
  <div id="message">
    <h2>404</h2>
    <h1>Page Not Found</h1>
    <p>
      No match was found for <code>{location.pathname}</code> on TSKP.
      Please check the URL for mistakes and try again.
    </p>
  </div>
);

export default NotFoundComponent;
