import React from "react";

export default function ExpertPanel() {
  return (
    <div>
      <h2>Subject Expert</h2>
      <br />
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          Prof (Dr.) Sheilana Devi Ramdoo, Professor School of performing Arts,
          Mahatma Gandhi institute Mauritius.
        </li>
        <li className="list-group-item">
          S. Balbir Singh Kanwal, Renowned Musicologist , London, England.
        </li>
        <li className="list-group-item">
          S. Harjit Singh, President, Sarb Akal Music society Of Calgary
          (Canada).
        </li>
        <li className="list-group-item">
          S. Manjit Singh, Director, Rhythm School of Indian Music Ltd, Auckland
          New Zealand.
        </li>
        <li className="list-group-item">
          Dr. Subahgya Vardan, Director, North Zone Culture Centre, Patiala,
          India.
        </li>
        <li className="list-group-item">
          Vidhushi (Dr.) Krishna Bisht, Former Dean, The Faculty of Music and
          Fine Arts, University of Delhi, India.
        </li>
        <li className="list-group-item">
          Prof. (Dr.) Gurnam Singh, Former Head and Dean Department of Gurmat
          Sangeet, Punjabi University Patiala, India.
        </li>
        <li className="list-group-item">
          Prof. (Dr.) Ravi Sharma, Former Dean &amp; Head, Department of Music,
          M.D.U Rohtak (HR), India.
        </li>
        <li className="list-group-item">
          Prof. (Dr.) R.S Shandil, Department of Music, Himachal Pardesh
          University Shimla (HP), India.
        </li>
        <li className="list-group-item">
          Prof. (Dr.) Yashpal Sharma, Department of Music, Punjabi University
          Patiala, India.
        </li>
        <li className="list-group-item">
          Prof. (Dr.) Anupam Mahajan, The Faculty of Music and Fine Arts,
          University of Delhi, India.
        </li>
        <li className="list-group-item">
          Prof. (Dr.) Bharti Sharma , Department of Music, M.D.U Rohtak, India.
        </li>
        <li className="list-group-item">
          Prof. (Dr.) Pankaj Mala Sharma, Head, Department of Music, Punjab
          University Chandigarh, India.
        </li>
        <li className="list-group-item">
          Dr. Sobha Koser, Registrar, Pracheen Kala Kendra Chandigarh (Renowned
          Dance Artist), India.
        </li>
        <li className="list-group-item">
          Prof. (Dr.) Rajinder Singh Gill, Head Department of Music, Punjabi
          University Patiala, India.
        </li>
        <li className="list-group-item">
          Prof. (Dr.) Nivedita Uppal, Department of Music, Punjabi University
          Patiala, India.
        </li>
        <li className="list-group-item">
          Prof. (Dr.) Gurpreet Kaur, Dean &amp; Head, Department of Music, GNDU
          Amritsar, India.
        </li>
        <li className="list-group-item">
          Prof. (Dr.) Shalinder Goswami, The Faculty of Music and Fine Arts,
          University of Delhi, India.
        </li>
        <li className="list-group-item">
          Prof. (Dr.) Rajeev Verma, The Faculty of Music and Fine Arts,
          University of Delhi, India.
        </li>
        <li className="list-group-item">
          Prof. (Dr.) Hukam Chand, Head, Department of Music, M.D.U Rohtak,
          India.
        </li>
        <li className="list-group-item">
          Dr. Malika Benerji, Professor IGNOU (Retd.)
        </li>
        <li className="list-group-item">
          Dr. Sunada Pathak, Associate Professor Mata Sundri College Delhi,
          India.
        </li>
        <li className="list-group-item">
          Prof. (Dr.) Manmohan Sharma, Professor, Punjabi University Patiala,
          India.
        </li>
        <li className="list-group-item">
          Dr. Jagmohan Sharma, Associate Professor, GCG Patiala, India.
        </li>
        <li className="list-group-item">
          Prof. (Dr.) Rajesh Sharma, Assistant Professor, Department of Music,
          GNDU Amritsar, India.
        </li>
        <li className="list-group-item">
          Dr. Alankar Singh, Assistant Professor, Department of Music, Punjabi
          University Patiala, India.
        </li>
        <li className="list-group-item">
          Dr. Jyoti Sharma, Assistant Professor, Department of Music, Punjabi
          University Patiala, India.
        </li>
        <li className="list-group-item">
          Dr. Jagjit Singh Assistant Professor Khalsa College Patiala, India.
        </li>
      </ul>
    </div>
  );
}
