import React from "react"
import JeetRam from "../images/Dr. Jeet Ram.jpg"
import RajeshKelkar from "../images/rajesh kelkar.jpeg"
// import RashpalVirk from "../images/rashpal virk.jpg"
import YashpalSharma from "../images/DR YASHPAL SHARMA.jpg"
import HarjitSingh from "../images/Harjit Singh Sarb Akal.jpg"
import ManjitSingh from "../images/MANJIT SINGH.jpg"
import BalbirSingh from "../images/BALBIR SINGH kanwal.jpg"
import SarojGhosh from "../images/Saroj Ghosh.jpg"
// import Shuchismita from "../images/shuchismita.jpg"
import Anita from "../images/anita.jpeg"
import Mritunjay from "../images/Mritunjay.jpg"

function EditorialBoardComponent() {
  return (
    <div>
      <h2>Editorial Board</h2>
      <br />
      {/* <div className='card'>
        <h5 className='card-header font-weight-bold'>
          (Editor) Dr. Rishpal Singh Virk
        </h5>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-12 col-md-3 col-lg-3 mb-4 mb-md-0 mb-lg-0 text-center'>
              <img
                className='img-fluid'
                width='127'
                src={RashpalVirk}
                alt='Dr. Rishpal Singh Virk'
              />
            </div>
            <div className='col-sm-12 col-md-9 col-lg-9'>
              <span className='font-weight-bold'>Permanent Address:</span> House
              No. 119A, Kamal Enclave, Puda Colony, City Patran, Distt Patiala,
              State Punjab, India -147105
              <p className='card-text'>
                Contact: <a href='tel:+919915105008'>+91-9915105008</a>
                <br />
                Email:{" "}
                <a href='mailto:rishpalsinghvirk@gmail.com'>
                  rishpalsinghvirk@gmail.com
                </a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <br /> */}
      {/* <div className='card'>
        <h5 className='card-header font-weight-bold'>
          Prof (Dr.) Yashpal Sharma
        </h5>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-12 col-md-3 col-lg-3 mb-4 mb-md-0 mb-lg-0 text-center'>
              <img className='img-fluid' src={YashpalSharma} />
            </div>
            <div className='col-sm-12 col-md-9 col-lg-9'>
              <span className='font-weight-bold card-title'>
                Professor, Department of Music
              </span>
              <p className='card-text'>
                Punjabi University
                <br />
                Patiala (Punjab, India)
                <br />
                Contact: <a href='tel:+918288005159'>+91-8288005159</a>
                <br />
                Email:{" "}
                <a href='mailto:profyash@gmail.com'>profyash@gmail.com</a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <br /> */}
      <div className='card'>
        <h5 className='card-header font-weight-bold'>Dr. Saroj Ghosh</h5>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-12 col-md-3 col-lg-3 mb-4 mb-md-0 mb-lg-0 text-center'>
              <img className='img-fluid' src={SarojGhosh} />
            </div>
            <div className='col-sm-12 col-md-9 col-lg-9'>
              <span className='font-weight-bold card-title'>
                Former Professor, Department of Music
              </span>
              <p className='card-text'>
                Panjab University Chandigarh
                <br />
                Contact: <a href='tel:+919877270796'>+91 9877270796</a>
                <br />
                Email:{" "}
                <a href='mailto:sarojghosh949@gmail.com'>sarojghosh949@gmail.com</a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className='card'>
        <h5 className='card-header font-weight-bold'>Dr. Jeet Ram Sharma</h5>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-12 col-md-3 col-lg-3 mb-4 mb-md-0 mb-lg-0 text-center'>
              <img className='img-fluid' src={JeetRam} />
            </div>
            <div className='col-sm-12 col-md-9 col-lg-9'>
              <span className='font-weight-bold card-title'>
                Professor, Department of Performing Arts,
                H.P University Shimla 171005
              </span>
              <p className='card-text'>
                Panjab University Chandigarh
                <br />
                Contact: <a href='tel:+918628983101'>+91 8628983101</a>
                <br />
                Email:{" "}
                <a href='mailto:jrmusic.sharma@gmail.com'>jrmusic.sharma@gmail.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className='card'>
        <h5 className='card-header font-weight-bold'>Dr. Anita Seewoogoolam</h5>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-12 col-md-3 col-lg-3 mb-4 mb-md-0 mb-lg-0 text-center'>
              <img className='img-fluid' style={{ maxHeight: '250px' }} src={Anita} />
            </div>
            <div className='col-sm-12 col-md-9 col-lg-9'>
              <span className='font-weight-bold card-title'>
                Head/ Senior Lecturer
                Vocal Music Department
              </span>
              <p className='card-text'>
                School of Performing Arts
                Mahatma Gandhi Institute
                Moka, Mauritius
                <br />
                Contact: <a href='tel:+23057916445'>+23057916445</a>
                <br />
                Email:{" "}
                <a href='mailto:a.seewoogoolam@gmail.com'>a.seewoogoolam@gmail.com</a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <br />
      <div className='card'>
        <h5 className='card-header font-weight-bold'>Dr. Mritunjay Sharma</h5>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-12 col-md-3 col-lg-3 mb-4 mb-md-0 mb-lg-0 text-center'>
              <img className='img-fluid' style={{ maxHeight: '250px' }} src={Mritunjay} />
            </div>
            <div className='col-sm-12 col-md-9 col-lg-9'>
              <span className='font-weight-bold card-title'>
                Assistant Professor
              </span>
              <p className='card-text'>
                Department of Performing Arts (Instrumental Music)
                Himachal Pradesh University, Summerhill, Shimla171005
                <br />
                Contact: <a href='tel:+919318510611'>+91-9318510611</a>
                <br />
                Email:{" "}
                <a href='mailto:drmritunjaysharma@gmail.com'>drmritunjaysharma@gmail.com</a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <br />
      <div className='card'>
        <h5 className='card-header font-weight-bold'>Dr. Rajesh Gopal Rao Kelkar</h5>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-12 col-md-3 col-lg-3 mb-4 mb-md-0 mb-lg-0 text-center'>
              <img className='img-fluid' src={RajeshKelkar} />
            </div>
            <div className='col-sm-12 col-md-9 col-lg-9'>
              <span className='font-weight-bold card-title'>
                Professor, Department of Music
              </span>
              <p className='card-text'>
                The Maharaja Sayajirao University of Baroda
                <br />
                Contact: <a href='tel:+919924260175'>+91 9924260175</a>
                <br />
                Email:{" "}
                <a href='mailto:a.rajeshgkelkar@gmail.com'>a.rajeshgkelkar@gmail.com</a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className='card'>
        <h5 className='card-header font-weight-bold'>S. Harjeet Singh</h5>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-12 col-md-3 col-lg-3 mb-4 mb-md-0 mb-lg-0 text-center'>
              <img className='img-fluid' width='187' src={HarjitSingh} />
            </div>
            <div className='col-sm-12 col-md-9 col-lg-9'>
              <span className='font-weight-bold card-title'>
                President, Sarb Akal Music society Of Calgary
              </span>
              <p className='card-text'>
                1210 – 52A Street SE- Calgary,
                <br />
                AB T2A 1V4
                <br />
                Contact: <a href='tel:4037011760'>(403) 701-1760</a>
                <br />
                Email:{" "}
                <a href='mailto:sarbakalmusic@gmail.com'>
                  sarbakalmusic@gmail.com
                </a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className='card'>
        <h5 className='card-header font-weight-bold'>S. Balbir Singh Kanwal</h5>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-12 col-md-3 col-lg-3 mb-4 mb-md-0 mb-lg-0 text-center'>
              <img
                className='img-fluid'
                width='127'
                height='170'
                src={BalbirSingh}
              />
            </div>
            <div className='col-sm-12 col-md-9 col-lg-9'>
              <span className='font-weight-bold card-title'>
                Renowned Musicologist
              </span>
              <p className='card-text'>
                London, England
                <br />
                Contact: <a href='tel:+442085531655'>+442085531655</a>
                <br />
                Email:{" "}
                <a href='mailto:kanwalbalbir1943@gmail.com'>
                  kanwalbalbir1943@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className='card'>
        <h5 className='card-header font-weight-bold'>S. Manjit Singh</h5>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-12 col-md-3 col-lg-3 mb-4 mb-md-0 mb-lg-0 text-center'>
              <img className='img-fluid' src={ManjitSingh} />
            </div>
            <div className='col-sm-12 col-md-9 col-lg-9'>
              <span className='font-weight-bold card-title'>
                Director, Rhythm School of Indian Music Ltd
              </span>
              <p className='card-text'>
                26 Stonex Road, Papatoetoe,
                <br />
                Auckland, New Zealand
                <br />
                Contact: <a href='tel:+6421595941'>+6421595941</a>
                <br />
                Email:{" "}
                <a href='mailto:rhythmschool@live.com'>rhythmschool@live.com</a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  )
}

export default EditorialBoardComponent
