import React from "react"

function TermsAndConditionsComponent() {
  return (
    <div className='text-justify'>
      <h3 className='mb-4'>Author Guidelines</h3>
      <ol className='text-justify'>
        <li>This journal has online version only.</li>
        <li>
          Authors are encouraged to submit their hitherto unpublished work for
          publication. All submitted papers will be subjected to Referred Board.
        </li>
        <li>
          The selection and publication of research paper are done after
          recommendation of referees and subject experts.
        </li>
        <li>
          The research papers should be written according to RESEARCH
          METHODOLOGY.{" "}
          <a
            href='https://bit.ly/2ZRWkH1'
            target='_blank'
            className='font-weight-bold'
          >
            Sample Paper
          </a>
        </li>
        <li>
          The research papers should have bibliography, footnotes, references,
          suggestions and findings.
        </li>
        <li>The titles of your research papers should be appropriate.</li>
        <li>
          Your research papers should be original and unpublished.{" "}
          <a
            href='https://bit.ly/tskp-copyright-declaration'
            target='_blank'
            className='font-weight-bold'
          >
            Copyright Declaration
          </a>
        </li>
        <li>Research Paper should not more than 4000 words.</li>
        <li>You can also send your Research Papers by Website/email/CD etc.</li>
        <li>Research paper should be typed in MS Word only.</li>
        <li>
          For PUNJABI, HINDI and SANSKRIT papers, use only these fonts :
          <ul>
            <li>
              <b className='font-weight-bold'>For Punjabi:</b> Anmollipi (Font
              size:12)
            </li>
            <li>
              <b className='font-weight-bold'>For Hindi:</b> Kruti Dev-10 (Font
              size : 17)
            </li>
            <li>
              <b className='font-weight-bold'>For ENGLISH:</b> Times New Roman
              (Font size : 15)
            </li>
          </ul>
        </li>
      </ol>
      <h5>Review Process</h5>
      <ol>
        <li>
          Each manuscript will be primarily examined by the editor, and then
          forwarded to referee for blind review. The research paper shall be
          published subject to recommendation of referee. The review process may
          take up to a week to one month (Maximum two months in extra ordinary
          circumstances).
        </li>
        <li>
          The author&#39;s shall be informed about the selection/rejection of
          the article/paper by e-mail only. However, the journal shall publish
          the article/papers of the authors completing the formalities in due
          time mentioned in the selection letter. The rejected papers shall not
          be returned.
        </li>
        <li>
          In case of acceptance of the article and completion of publication
          formalities by the author, journal reserves the right of making
          amendments in the final draft of the research paper to suit the
          journal&#39;s requirement. 4. All rights to Edit/Reject the research
          paper are reserved with Editor/Subject Expert/ Referee.
        </li>
        <li>
          Please e-mail the paper (in MS-Word) to: 
          <a href='mailto:trivainikhojpatrika@gmail.com'>
            trivainikhojpatrika@gmail.com
          </a>
        </li>
        <li>
          Although this is an international registered research journal but in
          any case or circumstances if any university/college/institute/society
          denies to accept or recognize author&#39;s/research scholar&#39;s
          published research papers in the journal, then it will not be the
          responsibility of editor, publisher, management, editorial board,
          referee or subject experts.
        </li>
        <li>All right reserved @ editor.</li>
      </ol>

      <p>
        For More details regarding sending research papers you can contact to
        Chief Editor Dr. Rishpal Singh Virk on his Mob. No.{" "}
        <a href='tel:+919915105008'>9915105008</a>,{" "}
        <a href='tel:+919915205008'>9915205008</a>
      </p>
    </div>
  )
}

export default TermsAndConditionsComponent
