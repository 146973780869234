import React from "react";
import { Link } from "react-router-dom";

function NavbarComponent() {
  return (
    <div>
      <div className="d-none d-md-block d-lg-block text-uppercase text-center pt-3">
        <small className="font-weight-bold">
          International peer reviewed refereed Journal
        </small>
        <br />
        <Link className="h2" to="/">
          Trivaini Sangeetak Khoj Patrika
        </Link>
        <br />
        <span className="font-weight-bold text-danger">
          ISSN 2700-2926 (Online)
        </span>
        <br />
        <small className="font-weight-bold">
          Email:{" "}
          <a
            href="mailto:trivainikhojpatrika@gmail.com"
            className="text-lowercase"
          >
            trivainikhojpatrika@gmail.com
          </a>{" "}
          Ph: <a href="tel:+919915105008">+919915105008</a>,{" "}
          <a href="tel:+919915205008">+919915205008</a>
        </small>
      </div>
      <nav className="pt-lg-3 pt-md-3 pt-xl-3 navbar navbar-expand-lg navbar-light bg-light border-top-0">
        <Link className="navbar-brand d-md-none d-lg-none d-xl-none" to="/">
          Trivaini Sangeetak
          <br />
          Khoj Patrika
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarColor03"
          aria-controls="navbarColor03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarColor03">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/patron">
                Patrons
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/editor">
                Editor
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/editorial-board">
                Editorial Board
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/expert-panel">
                Subject Expert
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/terms-and-conditions">
                Author Guidelines
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/prev-issues">
                Previous Issues
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
          <Link to="/submit-paper" className="btn btn-md btn-primary subpprbtn">
            Submit Paper Now
          </Link>
        </div>
      </nav>
    </div>
  );
}

export default NavbarComponent;
