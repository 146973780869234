import React from "react"
import MusicVector from "../images/music_vector.svg"

function AboutComponent() {
  return (
    <div className='row'>
      <div className='col-sm-12 col-md-12 col-lg-12'>
        <div className='card mb-3'>
          <h5 className='card-header'>
            Trivaini Sangeetak Khoj Patrika : Aim and Scope
          </h5>
          <div className='card-body'></div>
          <img
            style={{height: "200px", width: "100%", display: "block"}}
            src={MusicVector}
            alt='About Journal'
          />
          <div className='card-body'>
            <p className='card-text about-text text-justify'>
              The Trivaini Sangeetak Khoj Patrika is a International peer review
              multi language referred journal published two times a year
              (Bi-Annual). Aims of this Journal promote and propagate the
              priceless heritage of Indian music. Manuscripts published are
              scholarly works, representing empirical research in a variety of
              modalities. They enhance knowledge regarding the music with a
              special interest toward an international constituency. Manuscripts
              report results of quantitative or qualitative research studies,
              summarize bodies or research, present theories, models, or
              philosophical positions, etc. Papers show relevance to advancing
              the practice of music. All manuscripts should contain evidence of
              a scholarly approach and be situated within the current
              literature. Implications for learning and teaching of music should
              be clearly stated, relevant, contemporary, and of interest to an
              international readership. 'Trivaini' Sangeetak Khoj Patrika’
              intends to provide a platform for ventilating the in-house
              research side by side with research in all areas related to music
              in the rest of the world. It intends to facilitate interaction of
              objective research between different genres of music in the world.
              <br />
              'Trivaini' Sangeetak Khoj Patrika’ publishes original papers,
              review papers, conceptual framework, analytical and simulation
              models, case studies, empirical research, technical notes, and
              book reviews. Special Issues devoted to important topics in
              Performing Art will occasionally be published.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutComponent
