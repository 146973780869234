import React from "react"
import JaswantSingh from "../images/JASWANT SINGH.jpg"
import RamandeepKaur from "../images/ramandeep.jpg"

function EditorComponent() {
  return (
    <div>
      <h2>Patrons</h2>
      <br />
      <div className='card'>
        <h5 className='card-header font-weight-bold'>Jaswant Singh</h5>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-12 col-md-3 col-lg-3 mb-4 mb-md-0 mb-lg-0 text-center'>
              <img
                className='img-fluid'
                width='150'
                src={JaswantSingh}
                alt='Jaswant Singh'
              />
            </div>
            <div className='col-sm-12 col-md-9 col-lg-9'>
              <p className='card-text my-5'>
                <span className='font-weight-bold'>Permanent Address:</span>{" "}
                Pacelliufer 1, 54290, Trier Germany
                <br />
                <span className='font-weight-bold'>Contact:</span>{" "}
                <a href='tel:+4915214980705'>+4915214980705</a>
                <br />
                <span className='font-weight-bold'>Email:</span>{" "}
                <a href='mailto:jaswal@gmx.de'>jaswal@gmx.de</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className='card'>
        <h5 className='card-header font-weight-bold'>Dr. Ramandeep Kaur</h5>
        <div className='card-body'>
          <div className='row'>
            <div className='col-sm-12 col-md-3 col-lg-3 mb-4 mb-md-0 mb-lg-0 text-center'>
              <img
                className='img-fluid'
                width='150'
                src={RamandeepKaur}
                alt='Ramandeep Kaur'
              />
            </div>
            <div className='col-sm-12 col-md-9 col-lg-9'>
              <p className='card-text my-5'>
                <span className='font-weight-bold'>Permanent Address:</span>{" "}
                Address: House No. 119A, Kamal Enclave,
                Puda Colony, City Patran, Distt Patiala, State Punjab, India -147105
                <br />
                <span className='font-weight-bold'>Contact:</span>{" "}
                <a href='tel:+919915205008'>+91 9915205008</a>
                <br />
                <span className='font-weight-bold'>Email:</span>{" "}
                <a href='mailto:rish_virk@yahoo.co.in'>rish_virk@yahoo.co.in</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default EditorComponent
