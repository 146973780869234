import React from "react";
import RashpalVirk from "../images/rashpal virk.jpg";

function EditorComponent() {
  return (
    <div>
      <h2>Editor</h2>
      <br />
      <div className="card">
        <h5 className="card-header font-weight-bold">Dr. Rishpal Singh Virk</h5>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3 mb-4 mb-md-0 mb-lg-0 text-center">
              <img
                className="img-fluid"
                width="150"
                src={RashpalVirk}
                alt="Dr. Rishpal Singh Virk"
              />
            </div>
            <div className="col-sm-12 col-md-9 col-lg-9 my-4">
              <p>
                <span className="font-weight-bold">Permanent Address:</span>{" "}
                House No. 119A, Kamal Enclave, Puda Colony, City Patran, Distt
                Patiala, State Punjab, India -147105
                <br />
                {/* <span className="font-weight-bold">Official Address:</span>{" "}
                Assistant Professor and Head Post Graduate Department of Music,
                Guru Nanak College Budhlada Distt Mansa (Punjab)
                <br /> */}
                <span className="font-weight-bold">Contact:</span>{" "}
                <a href="tel:+919915105008">+91-9915105008</a>,{" "}
                <a href="tel:+919915205008">+91-9915205008</a>
                <br />
                <span className="font-weight-bold">Email:</span>{" "}
                <a href="mailto:rishpalsinghvirk@gmail.com">
                  rishpalsinghvirk@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditorComponent;

{
  /* <div className="card mb-3">
        <h4 className="card-header">Dr. Rishpal Singh Virk</h4>
        <img
          className="pt-4 m-auto"
          style={{ width: "200px" }}
          src={RashpalVirk}
          alt="Rashpal Singh Virk"
        />
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <span className="font-weight-bold">Permanent Address:</span> House
            No. 119A, Kamal Enclave, Puda Colony, City Patran, Distt Patiala,
            State Punjab, India -147105
          </li>
          <li className="list-group-item">
            <span className="font-weight-bold">Offical Address:</span> Assistant
            Professor and Head Post Graduate Department of Music, Guru Nanak
            College Budhlada Distt Mansa (Punjab)
          </li>
          <li className="list-group-item">
            <span className="font-weight-bold">Contact No:</span>{" "}
            <a href="tel:+919915105008">+91-9915105008</a>,{" "}
            <a href="tel:+919915205008">+91-9915205008</a>
          </li>
          <li className="list-group-item">
            <span className="font-weight-bold">Email:</span>{" "}
            <a href="mailto:rishpalsinghvirk@gmail.com">
              rishpalsinghvirk@gmail.com
            </a>
          </li>
        </ul>
        <div className="card-footer text-muted">
          TRIVAINI SANGEETAK KHOJ PATRIKA
        </div>
      </div> */
}
