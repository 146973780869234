import React from "react"
import s1 from "../images/s1.jpg"
import s2 from "../images/s2.jpg"
import ContactComponent from "./Contact"
import AboutComponent from "./About"
import CurrentIssue from "./CurrentIssue"
// import ImgModal from "./ImgModal"

function HomeComponent() {
  return (
    <div>
      <div className='row'>
        <div className='col-sm-12 col-md-7 col-lg-7 mb-4'>
          <div
            id='carouselExampleIndicators'
            className='carousel slide'
            data-ride='carousel'
            style={{ minHeight: "100%" }}
          >
            <ol className='carousel-indicators'>
              <li
                data-target='#carouselExampleIndicators'
                data-slide-to={0}
                className='active'
              />
              <li data-target='#carouselExampleIndicators' data-slide-to={1} />
            </ol>
            <div className='carousel-inner'>
              <div className='carousel-item active'>
                <img className='d-block w-100' src={s1} alt='First slide' />
              </div>
              <div className='carousel-item'>
                <img className='d-block w-100' src={s2} alt='Second slide' />
              </div>
            </div>
            <a
              className='carousel-control-prev'
              href='#carouselExampleIndicators'
              role='button'
              data-slide='prev'
            >
              <span className='carousel-control-prev-icon' aria-hidden='true' />
              <span className='sr-only'>Previous</span>
            </a>
            <a
              className='carousel-control-next'
              href='#carouselExampleIndicators'
              role='button'
              data-slide='next'
            >
              <span className='carousel-control-next-icon' aria-hidden='true' />
              <span className='sr-only'>Next</span>
            </a>
          </div>
        </div>
        <div className='col-sm-12 col-md-5 col-lg-5 mb-4'>
          <div
            className='card bg-secondary'
            style={{ minHeight: "95%", maxHeight: "100%" }}
          >
            <CurrentIssue />
            {/* <h5 className='card-header text-center'>Current Issue</h5>
            <div className='card-body' style={{position: "relative"}}>
              <p className='card-text text-center font-weight-bold'>
                <Link to='/current-issue'>
                  <img
                    src={CurrentJournalCover}
                    width='200px'
                    alt='Current Issue'
                    data-toggle='modal'
                    data-target='#ImgModal'
                    style={{cursor: "pointer"}}
                  />
                  <p />
                  Click here to download
                </Link>
              </p>
            </div> */}
          </div>
        </div>
      </div>
      <AboutComponent />
      <ContactComponent />
      {/* <ImgModal imgsrc={CurrentJournalCover} title='Cover Page' /> */}
    </div>
  )
}

export default HomeComponent
