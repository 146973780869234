import React from "react";
import "./App.css";
import NavbarComponent from "./components/Navbar";
import HomeComponent from "./components/Home";
import FooterComponent from "./components/Footer";
import ExpertPanelComponent from "./components/ExpertPanel";
import PatronComponent from "./components/Patron";
import EditorComponent from "./components/Editor";
import PrevJournal from "./components/PrevJournal";
import CurrentIssue from "./components/CurrentIssue";
import EditorialBoardComponent from "./components/EditorialBoard";
import ContactComponent from "./components/Contact";
import SubmitPaperComponent from "./components/SubmitPaper";
import TermsAndConditionsComponent from "./components/TermsAndConditions";
import NotFoundComponent from "./components/NotFound";
import TechSupport from "./components/TechSupport";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <Router>
      <NavbarComponent />
      <div className="container my-4">
        <Switch>
          <Route path="/" exact component={HomeComponent} />
          <Route path="/patron" component={PatronComponent} />
          <Route path="/editor" component={EditorComponent} />
          <Route path="/editorial-board" component={EditorialBoardComponent} />
          <Route path="/submit-paper" component={SubmitPaperComponent} />
          <Route path="/expert-panel" component={ExpertPanelComponent} />
          <Route path="/prev-issues" component={PrevJournal} />
          <Route path="/current-issue" component={CurrentIssue} />
          <Route path="/tech-support" component={TechSupport} />
          <Route path="/contact" component={ContactComponent} />
          <Route
            path="/terms-and-conditions"
            component={TermsAndConditionsComponent}
          />
          <Route component={NotFoundComponent} />
        </Switch>
      </div>
      <FooterComponent />
    </Router>
  );
}

export default App;
