import React, {useEffect, useState} from "react"
import * as firebase from "firebase"

export default function PrevJournal() {
  const [prevIssueData, setPrevIssueData] = useState([])
  const [arrowIcon, setArrowIcon] = useState(
    "M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
  )
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const db = firebase.database()
    db.ref("/prevIssues").on("value", snap => {
      setPrevIssueData(snap.val())
      setIsLoading(false)
    })
  }, [])

  return (
    <div>
      <h2>Previous Issues</h2>
      <br />
      {isLoading && (
        <div className='loader-container'>
          <div className='lds-ellipsis'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <ul className='list-group'>
        {prevIssueData.map((data, index) => {
          return (
            <div key={index}>
              <li
                className='font-weight-bold list-group-item d-flex justify-content-between align-items-center'
                data-toggle='collapse'
                href={`#collapseExample${index}`}
                role='button'
                aria-expanded='false'
                aria-controls='collapseExample'
                style={{userSelect: "none"}}
                onClick={() => {
                  if (
                    arrowIcon ===
                    "M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  ) {
                    setArrowIcon(
                      "M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                    )
                  } else {
                    setArrowIcon(
                      "M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    )
                  }
                }}
              >
                {data.title}
                <svg
                  className='bi bi-chevron-down'
                  width='1em'
                  height='1em'
                  viewBox='0 0 16 16'
                  fill='currentColor'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path fillRule='evenodd' d={arrowIcon} />
                </svg>
              </li>
              <div className='collapse' id={`collapseExample${index}`}>
                <ul className='list-group'>
                  {data.books.map((book, i) => {
                    return (
                      <li
                        key={i}
                        className='font-weight-bold list-group-item d-flex justify-content-between align-items-center'
                      >
                        {book.title} ------ {book.author}
                        <a
                          href={book.link}
                          className='btn btn-primary btn-sm'
                          target='_blank'
                        >
                          Download/View
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )
        })}
      </ul>
    </div>
  )
}
