import React, { useState } from "react";

function ContactComponent() {
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [msg, setMsg] = useState();
  const [submitBtnLabel, setSubmitBtnLabel] = useState("SEND");

  const handleContactSubmit = (e) => {
    e.preventDefault();
    setSubmitBtnLabel("Sending..");
    fetch(`${process.env.REACT_APP_SERVER_URL}/sendMsg`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        msg: `Contact Form: New message has arrived:<p/>Name: ${name},<br/>E-Mail: ${email},<br/>Message: ${msg}<p/>Thanks`,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.message === "OK") {
          setName("");
          setEmail("");
          setMsg("");
          setSubmitBtnLabel("Message Sent");
          setTimeout(() => setSubmitBtnLabel("Send"), 4000);
        } else {
          console.log(data.message);
          setSubmitBtnLabel("Try Again Later");
          setTimeout(() => setSubmitBtnLabel("Send"), 4000);
        }
      })
      .catch((e) => {
        console.log(e);
        setSubmitBtnLabel("Try Again Later");
        setTimeout(() => setSubmitBtnLabel("Send"), 4000);
      });
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6 col-lg-6 my-2">
        <div className="card" style={{ minHeight: "100%" }}>
          <div className="card-body">
            <h4 className="card-title">Contact Info</h4>
            <h6 className="card-subtitle mb-4 text-muted">Get in touch</h6>
            <div className="card-text">
              <span className="font-weight-bold">S. JASWANT SINGH</span>
              <br />
              <span className="font-weight-bold">Call: </span>{" "}
              <a href="tel:+4915214980705">+4915214980705</a>
              <br />
              <span className="font-weight-bold">Visit: </span>Pacelliufer 1
              54290, Trier Germany.
              <br />
              <span className="font-weight-bold">Email:</span>{" "}
              <a href="mailto:jaswal@gmx.de">jaswal@gmx.de</a>
              <div className="text-center mt-2 embed-responsive embed-responsive-16by9" style={{ maxHeight: '100px' }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2578.220223409426!2d6.626411315705947!3d49.74429997938425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47957b5e293492b5%3A0xddf29ceda9698c22!2sPacelliufer%201%2C%2054290%20Trier%2C%20Germany!5e0!3m2!1sen!2sin!4v1626598673822!5m2!1sen!2sin"
                  frameBorder="0"
                  className="embed-responsive-item"
                  style={{ border: "0px" }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                  loading="lazy"
                ></iframe>
              </div>
              <hr />
              <span className="font-weight-bold">Dr. Rishpal Singh Virk</span>
              <br />
              <span className="font-weight-bold">Call: </span>{" "}
              <a href="tel:+919915105008">+91-9915105008</a>,{" "}
              <a href="tel:+919915205008">+91-9915205008</a>
              <br />
              <span className="font-weight-bold">Visit: </span>House No.
              119A, Kamal Enclave, Puda Colony, City Patran, Distt
              Patiala-147105, India.
              <br />
              <span className="font-weight-bold">Email:</span>{" "}
              <a href="mailto:rishpalsinghvirk@gmail.com">
                rishpalsinghvirk@gmail.com
              </a>
              <div className="text-center mt-2 embed-responsive embed-responsive-16by9" style={{ maxHeight: '100px' }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3457.219199854539!2d76.05820666475068!3d29.94437298024874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3911b83fbd4fcdf5%3A0xba79a1fc4862299d!2sPuda%20Colony%2C%20Patran%2C%20Punjab%20147105!5e0!3m2!1sen!2sin!4v1586522535995!5m2!1sen!2sin"
                  frameBorder="0"
                  className="embed-responsive-item"
                  style={{ border: "0px" }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Contact Form</h4>
            <h6 className="card-subtitle mb-4 text-muted">
              Leave us a message
            </h6>
            <div className="card-text">
              <form onSubmit={handleContactSubmit}>
                <div className="form-group">
                  <label>Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <small id="emailHelp" className="form-text text-muted">
                    We'll never share your email with anyone else.
                  </small>
                </div>
                <div className="form-group">
                  <label>Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <small className="form-text text-muted">
                    Full name must include your first and last name.
                  </small>
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    rows="5"
                    className="form-control"
                    placeholder="Enter your message"
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                    required
                  ></textarea>
                  <small className="form-text text-muted">
                    Be as precise with your query as possible.
                  </small>
                </div>
                <button className="btn btn-primary">{submitBtnLabel}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default ContactComponent;
