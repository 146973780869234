import React from "react";
import { Link } from "react-router-dom";

function FooterComponent() {
  return (
    <div>
      <hr />
      <ul className="list-unstyled list-inline text-center mt-4">
        <li className="list-inline-item">
          <Link to="/">Homepage</Link>
        </li>
        <li className="list-inline-item">
          <Link to="/tech-support">Technical Support</Link>
        </li>
        <li className="list-inline-item">
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
      <p className="text-center">
        Designed &amp; Developed By{" "}
        <a href="https://bit.ly/iamhrwndr" target="_blank">
          Harwinder Singh
        </a>
        .
      </p>
    </div>
  );
}

export default FooterComponent;
